import { CollapseBase } from './collapse_base'

export default class extends CollapseBase {
 
  expand() {
    const getAllButtons = this.element.querySelectorAll('.collapsible__toggle')
    const getAllPanels = this.element.querySelectorAll('.collapsible__panel')

    getAllPanels.forEach(
      (panel,i) => {
        this.handleCollapse(
          panel,
          getAllButtons[i],
          true,
          250
        )
      }
    )

    window._paq.push([
      'trackEvent',
      'collapsible group expanded'
    ])
  }

  collapse() {
    const getAllButtons = this.element.querySelectorAll('.collapsible__toggle')
    const getAllPanels = this.element.querySelectorAll('.collapsible__panel')

    getAllPanels.forEach(
      (panel,i) => {
        this.handleCollapse(
          panel,
          getAllButtons[i],
          false,
          250
        )
      }
    )

    window._paq.push([
      'trackEvent',
      'collapsible group closed'
    ])
  }

}
