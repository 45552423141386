import { Controller } from 'stimulus'

export class CollapseBase extends Controller {

  targetOpened = new Event('targetopened', { bubbles: true })
  targetClosed = new Event('targetclosed', { bubbles: true })

  isTargetOpen = target => {
    return target.getAttribute('aria-hidden') === 'true' ? false : true
  }

  animOpen(element) {
    element.classList.add('is-visible')
    requestAnimationFrame(this.animOpen.bind(this, element))
  }
  animClose(element) {
    element.classList.remove('is-visible')
    requestAnimationFrame(this.animClose.bind(this, element))
  }

  handleCollapse(element, button, shouldOpen, delay) {
    // Wrangle all the styling and DOM-related toggling for target collapse/expansion
    element.style.maxHeight = shouldOpen ? `${element.scrollHeight}px` : null
    shouldOpen
      ? (
        element.setAttribute('aria-hidden', false),
        requestAnimationFrame(this.animOpen.bind(this, element)),
        element.dispatchEvent(this.targetOpened)
      )
      // Delay hiding long enough to first allow the CSS transition to do its thing
      : (
        element.setAttribute('aria-hidden', true),
        setTimeout(() => {
          requestAnimationFrame(this.animClose.bind(this, element))
          element.dispatchEvent(this.targetClosed)
        }, delay)
      )
    button.setAttribute('aria-expanded', shouldOpen)
  }
}
