import { Controller } from 'stimulus'
import { isVisible } from '../../utils.js'

// Enhances a basic <select> field into an accessible autocomplete component.

// The component built by the gov.uk team is pretty great, certainly one of
// the best already-built autocompletes out there:
// https://github.com/alphagov/accessible-autocomplete
// ...but it misses out on a few features that we need and their team has not
// been dealing with issues and pull requests for a while.

// So here’s this monster that addresses our needs, built mainly from these
// recommendations:
// https://adamsilver.io/blog/building-an-accessible-autocomplete-control/

export default class extends Controller {
  static targets = [
    'select',
    'combobox',
    'menu',
  ]

  static values = {
    selection: String,
    activeOption: String,
  }

  initialize() {

    // Set up the autocomplete’s markup
    // --------------------------------

    this.fieldOptions = [...this.selectTarget.options]
    this.fieldId = this.selectTarget.id

    // Hide the original select element:
    this.visuallyHideSelectEl(this.selectTarget)

    // Put together markup for the enhancement:
    let autocomplete =
      `<div class="autocomplete">
        ${this.comboboxTemplate(this.fieldId)}
        ${this.arrowTemplate()}
        <ul
          id="autocomplete-options--${this.fieldId}"
          role="listbox"
          data-form--autocomplete-target="menu"
          class="u-hidden">
        </ul>
        ${this.liveRegionTemplate()}
      </div>`

    // Change the original select element’s id, because now the field’s label
    // needs to point solely to the autocomplete’s combobox. This changed id
    // will still act as a validation hook, though.
    this.selectTarget.setAttribute('id', `${this.fieldId}-select`)

    // And finally insert the autocomplete enhancement:
    if (this.element.querySelector('[data-error-container]')) {
      let errorContainer = this.element.querySelector('[data-error-container]')
      errorContainer.insertAdjacentHTML('beforebegin', autocomplete)
    } else {
      this.element.insertAdjacentHTML('beforeend', autocomplete)
    }

    // Does the field already have a value? Show it:
    if (this.selectionValue) {
      let selectedOption = this.element.querySelector(`[value="${this.selectionValue}"]`)
      this.comboboxTarget.value = selectedOption.textContent.trim()
    }


    // Set up the autocomplete’s event listeners
    // -----------------------------------------

    // Bind the keyup callback to the right scope:
    let handleComboboxKeyup = this.handleComboboxKeyup.bind(this)
    this.comboboxTarget.addEventListener('keyup', handleComboboxKeyup)

    let handleMenuKeydown = this.handleMenuKeydown.bind(this)
    this.menuTarget.addEventListener('keydown', handleMenuKeydown)

    this.element.addEventListener('keydown', (event) => {
      if (event.keyCode === 13 || event.code === 'Enter') {
        // When interacting with this autocomplete, hitting the enter key
        // should only select options and not submit the form.
        event.preventDefault()
        return false
      }
      if (event.code === 'Tab' || event.code === 'Escape') {
        this.hideMenu()
      }
    })

    // Set up option selection event listener:
    this.element.addEventListener('click', (event) => {
      if (event.target.getAttribute('role') === 'option') {
        let option = event.target
        this.selectOption(option)
      }
    })

    document.addEventListener('click', (event) => {
      if (!this.element.contains(event.target)) {
        this.hideMenu()
      }

      if (event.target === this.comboboxTarget) {
        // Select text if there’s any:
        this.comboboxTarget.select()
        // Show all options:
        let menuOptions = this.getAllOptions()
        this.buildMenu(menuOptions)
        this.showMenu()
      }
    })
  }

  autocompleteChange = new CustomEvent('autocompletechange', {
    detail: this.selectTarget
  })

  selectionValueChanged() {
    // Watches this.selectionValue, and on change, updates the select element’s value to match:
    // https://stimulus.hotwired.dev/reference/values#change-callbacks
    this.selectTarget.value = this.selectionValue
    // Fire off a custom event for this, too, since the native `change` event won’t fire without
    // direct interaction with the select element itself:
    document.dispatchEvent(this.autocompleteChange)
  }

  createListboxItems(options) {
    let listboxItems = options.reduce((html, option) => {
      if (option.value) {
        html += `<li
            role="option"
            class="autocomplete__option"
            tabindex="-1"
            aria-selected="false"
            data-option-value="${option.value}"
            id="autocomplete-${this.fieldId}-${option.value}">
            ${option.text}
          </li>`
      }
      return html
    }, '')

    if (!listboxItems) {
      return `<li
          class="autocomplete__option autocomplete__option--no-results">
          ${gettext('No results found')}
        </li>`
    }

    return listboxItems
  }

  comboboxTemplate(id) {
    return `<input
        data-form--autocomplete-target="combobox"
        aria-owns="autocomplete-options--${id}"
        autocapitalize="none"
        type="text"
        autocomplete="off"
        aria-autocomplete="list"
        role="combobox"
        id="${id}"
        aria-expanded="false">`
  }

  arrowTemplate() {
    return `<svg
      focusable="false"
      class="autocomplete__dropdown-arrow-down icon icon--lg"
      width="20"
      height="20">
      <use href="#icon-arrow-down" xlink:href="#icon-arrow-down" />
    </svg>`
  }

  liveRegionTemplate() {
    return `<div aria-live="polite" role="status" class="u-visually-hidden"></div>`
  }

  visuallyHideSelectEl(selectEl) {
    // Hide the select element without preventing its value from being submitted to the server.
    selectEl.setAttribute('aria-hidden', 'true')
    selectEl.setAttribute('tabindex', '-1')
    selectEl.classList.add('u-visually-hidden')
  }

  updateStatus(optionCount) {
    let region = this.element.querySelector('[role="status"]')
    // Going with old-fashioned string concatenation for the following since gettext's message extraction isn't working properly with
    // string interpolation here...
    region.innerText = optionCount === 1 ? gettext('1 result available.') : optionCount + ' ' + gettext('results available.')
  }

  buildMenu(options) {
    this.menuTarget.innerHTML = ''
    this.menuTarget.insertAdjacentHTML('beforeend', this.createListboxItems(options))
  }

  showMenu() {
    this.menuTarget.classList.remove('u-hidden')
  }

  hideMenu() {
    this.menuTarget.classList.add('u-hidden')
  }

  getExactMatch(value) {
    // Returns an option if `value` matches that option’s textContent.
    return this.fieldOptions.find((option) => {
      if (option.textContent.trim().toLowerCase() === value.trim().toLowerCase()) {
        return option
      }
    })
  }

  getOptions(value) {
    let filteredOptions = this.fieldOptions.filter((option) => {
      if (
        option.value.trim().length > 0
        && option.textContent.toLowerCase().indexOf(value.toLowerCase()) > -1
        || option.hasAttribute('data-alt')
        && option.getAttribute('data-alt').indexOf(value.toLowerCase()) > -1
      ) {
        return option
      }
    })

    return filteredOptions.map((option) => {
      return {
        text: option.textContent,
        value: option.value
      }
    })
  }

  getAllOptions() {
    return this.fieldOptions
  }

  getFirstOption() {
    return this.menuTarget.firstChild
  }

  highlightOption(option) {
    if (this.activeOptionValue) {
      // If there’s a currently selected option, get it:
      let selectedOption = this.element.querySelector(`[id="${this.activeOptionValue}"]`)
      // And deselect it, if the element exists:
      if (selectedOption) selectedOption.setAttribute('aria-selected', 'false')
    }

    // Set new option as selected:
    option.setAttribute('aria-selected', 'true')
    // If the option isn’t visible within the menu...
    if (!isVisible(option)) {
      this.menuTarget.scrollTop += option.offsetTop
    }

    this.activeOptionValue = option.id
    option.focus()
  }

  selectOption(option) {
    let value = option.getAttribute('data-option-value')
    this.selectionValue = value
    this.hideMenu()
    this.comboboxTarget.value = option.textContent.trim()
    this.comboboxTarget.focus()
  }

  handleComboboxKeyup(event) {
    switch(event.code) {
      case 'Enter':
      case 'Escape':
      case 'ArrowUp':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Space':
      case 'Tab':
      case 'ShiftLeft':
      case 'ShiftRight':
        // Ignore these cases
        break
      case 'ArrowDown':
        this.handleComboboxDownArrow(event)
        break
      default:
        this.handleComboboxInput(event)
    }
  }

  handleComboboxDownArrow(event) {
    let option
    let options
    let value = this.comboboxTarget.value.trim()

    if (value.length === 0) {
      // If there’s no value, show the whole menu.
      options = this.getAllOptions()

      this.buildMenu(options)
      this.showMenu()
      option = this.getFirstOption()
      this.highlightOption(option)

    } else if (this.getExactMatch(value)) {
      // If there’s an exact match in the menu,
      // show the whole menu with that match highlighted.
      options = this.getAllOptions()

      this.buildMenu(options)
      this.showMenu()
      // Get the select element’s option...
      option = options.find((option) => option.textContent.trim().toLowerCase() === value.toLowerCase())
      // ...so that we can use its value to find the matching menu item:
      let matchingOption = this.menuTarget.querySelector(`[data-option-value="${option.value}"]`)
      // And then highlight that menu item:
      this.highlightOption(matchingOption)

    } else {
      // Otherwise, show the matching options.
      options = this.getOptions(value)

      if (options.length > 0) {
        this.buildMenu(options)
        this.showMenu()
        option = this.getFirstOption()
        this.highlightOption(option)
      }
    }
  }

  handleComboboxInput(event) {
    if (event.target.value.trim().length > 0) {
      // Get options based on what’s entered into the combobox input:
      let matchingOptions = this.getOptions(event.target.value.trim().toLowerCase())

      // Build menu based on those matches:
      this.buildMenu(matchingOptions)

      this.showMenu()

      // Update the live region:
      this.updateStatus(matchingOptions.length)
    }

    if (this.getExactMatch(event.target.value)) {
      // If an exact match to something in the list gets typed, update the
      // select element’s value to ensure that selection gets submitted and
      // reflect this in the combobox input's value.
      let match = this.getExactMatch(event.target.value)
      this.selectionValue = match.value
      this.comboboxTarget.value = match.textContent.trim()
      // Don’t highlight the matching option here, though. It’s better to
      // keep focus on the combobox input.
    } else {
      // But as soon as the combobox input’s value no longer matches something
      // in the list, clear the value to avoid mistakenly submitting it.
      this.selectionValue = ''
    }
  }

  handleMenuKeydown(event) {
    let selectedOption = this.element.querySelector(`[id="${this.activeOptionValue}"]`)

    switch(event.code) {
      case 'ArrowUp':
        // If the first option is focused, set focus to the combobox.
        // Otherwise, set focus to the previous option.
        event.preventDefault()
        if (selectedOption && !selectedOption.previousElementSibling) {
          this.comboboxTarget.focus()
          this.hideMenu()
        } else {
          this.highlightOption(selectedOption.previousElementSibling)
        }
        break
      case 'ArrowDown':
        // If there’s a next menu option, set focus to it.
        // If focus is already on the last menu option, do nothing.
        event.preventDefault()
        if (selectedOption.nextElementSibling) {
          this.highlightOption(selectedOption.nextElementSibling)
        }
        break
      case 'Tab':
        // Focus is leaving the menu, so hide it.
        this.hideMenu()
        break
      case 'Space':
        // When selecting an option with the space key, using preventDefault()
        // avoids adding an unneeded space to the option’s name in the combobox.
        event.preventDefault()
      case 'Enter':
        // Select the currently highlighted option and set focus to the combobox.
        this.selectOption(event.target)
        this.comboboxTarget.focus()
        this.hideMenu()
        break
      case 'Escape':
        // Hide the menu and set focus back to the combobox.
        this.comboboxTarget.focus()
        this.hideMenu()
        break
      default:
        // Keep focus on the combobox so users can continue typing.
        this.comboboxTarget.focus()
    }
  }


}
