import '@stimulus/polyfills'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
// Stimulus cheatsheet:
// https://gist.github.com/mrmartineau/a4b7dfc22dc8312f521b42bb3c9a7c1e

import {
  // getFocusableEls,
  focusOnElement,
} from './utils'

import IntersectionObserver from 'intersection-observer'
import './scroll-effect'
import './section-fade-handler'
import './province'

window.Stimulus = Application.start()
const context = require.context('./controllers', true, /\.js$/)
Stimulus.load(definitionsFromContext(context))


if (!window.django) {
  console.error("window.django not defined - ensure Django's jsi18n is loaded before this script")
}


// The only reason this plyr sprite madness is here is because it's comparatively
// less insane than the efforts required to make svg external linking polyfills
// actually function. If that situation should change, then awesome.
// const plyrCreatedEvent = new CustomEvent('plyrCreated')
const createPlyrSprites = function() {
  // The sprites only need to get set up once,
  // so ditch the event listener now that we're underway:
  document.removeEventListener('plyrCreated', createPlyrSprites)

  if (document.getElementById('plyr-sprites')) return

  const ajax = new XMLHttpRequest()
  ajax.open('GET', '/static/images/icons/plyr/plyr.svg', true)
  ajax.send()
  ajax.onload = function(e) {
    const spriteEl = document.createElement('div')
    spriteEl.setAttribute('id', 'plyr-sprites')
    spriteEl.innerHTML = ajax.responseText
    document.body.insertBefore(spriteEl, document.body.childNodes[0]);
  }
}
document.addEventListener('plyrCreated', createPlyrSprites)


function docReady(fn) {
  if (document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

docReady(function() {
  if (window.location.hash) {
    focusOnElement(window.location.hash)
  }
  window.addEventListener('hashchange', function(e) {
    focusOnElement(window.location.hash)
  })
})
