import { Controller } from 'stimulus'
import 'svg-classlist-polyfill'

export default class extends Controller {
  static targets = [ 'map', 'selectedProv' ]
  static values = { lang:String }

  connect() {
    this.mapClicked = false
    let provs = [...document.querySelectorAll('.prov')]
    let enableProvs = ['map-bc', 'map-ab', 'map-mb', 'map-nl', 'map-ns', 'map-pei', 'map-sk', 'map-nb']
    let result = provs.filter(item => enableProvs.includes(item.id))
    result.forEach(prov => prov.classList.add('is-enabled'))
    if (window.location.hash != '' && window.location.hash != '#provinces_legislation') {
      this.checkHashChange()
    }
    this.provinces = {
      en :{
        bc: 'British Columbia',
        ab: 'Alberta',
        sk: 'Saskatchewan',
        mb: 'Manitoba',
        nl: 'Newfoundland and Labrador',
        nb: 'New Brunswick',
        ns: 'Nova Scotia',
        pei: 'Prince Edward Island'
      },
      fr: {
        bc: 'Colombie-Britannique',
        ab: 'Alberta',
        sk: 'Saskatchewan',
        mb: 'Manitoba',
        nl: 'Terre-Neuve-et-Labrador',
        nb: 'Nouveau-Brunswick',
        ns: 'Nouvelle-Écosse',
        pei: 'Île-du-Prince-Édouard'
      }
    }
  }

  showName(e) {
    if (e.target.parentNode.classList.contains('is-enabled')) {
      this.selectedProvTarget.innerHTML = this.provinces[this.langValue][e.target.parentNode.id.replace('map-','')]
    }
  }

  hideName() {
    this.selectedProvTarget.innerHTML = ''
  }

  checkHashChange() {
    window.onpopstate = (e) => {
      if (this.mapClicked === false) {
        this.clearMap()
        let selectedProv = window.location.hash.replace('#','#map-')
        this.setActiveProv(document.querySelector(selectedProv))
      }
    }
  }

  setActiveProv(prov) {
    if (prov) {
      prov.classList.add('is-active')
    }
  }

  clearMap() {
    // forEach on nodeList doesn't work in IE11, needs to be converted into an array
    let activeProvs = [...document.querySelectorAll('.is-active')]
    activeProvs.forEach(item => item.classList.remove('is-active'))
  }

  handleRegion(e) {
    if (e.target.parentNode.classList.contains('is-enabled')) {
      this.mapClicked = true
      this.clearMap()
      this.setActiveProv(e.target.parentNode)
      dispatchEvent(new CustomEvent('mapUpdatePanels', {
        bubbles: true,
        detail: {
          categoryId: e.target.parentNode.id.replace('map-','')
        }
      }))
      this.mapClicked = false
    }
  }
}
