import { Controller } from 'stimulus'
import {
  isVisible
} from '../../utils'

export default class extends Controller {
  static targets = [
    'novalidate',
  ]

  // Handy references for the basic validation set up here:
  // https://css-tricks.com/form-validation-part-2-constraint-validation-api-javascript/
  // https://github.com/jorgemanrubia/rails-form-validations-example/blob/master/app/javascript/controllers/form_controller.js

  initialize() {
    this.element.setAttribute('novalidate', true)

    // Considerations when applying inline validation:
    // https://baymard.com/blog/inline-form-validation
    // https://alistapart.com/article/inline-validation-in-web-forms/

    // Initial inline validation should only happen on blur or submit:
    this.element.addEventListener('blur', this.onBlur, true)
    this.element.addEventListener('submit', this.onSubmit, false)

    // Inline validation while someone’s making changes to a field should only happen
    // _after_ the first modification/validation is made to that field, because it’s
    // confusing/annoying to have it tell you you’re wrong before you're even done typing.
    // So, these event listener callbacks are set up to confirm if the field is dirty
    // before moving on to validation.
    this.element.addEventListener('keyup', this.checkDirtyField)
    this.element.addEventListener('change', this.checkDirtyField)

    // The `change` event won't fire unless someone interacts directly with an element
    // that can fire off a `change` event, and therefore it won't happen at all with
    // a select element enhanced into an autocomplete, so listen to its custom change
    // event instead to validate its contents.
    document.addEventListener('autocompletechange', (event) => {
      if (!this.isFieldDirty(event.detail)) return
      this.validateField(event.detail)
    })
  }

  onBlur = (event) => {
    this.validateField(event.target)
  }

  checkDirtyField = (event) => {
    if (!this.isFieldDirty(event.target)) return
    this.validateField(event.target)
  }

  onSubmit = (event) => {
    if (this.hasNovalidateTarget && (this.novalidateTargets.indexOf(document.activeElement) !== -1)) {
      // If the above is true, then this form is multi-step, and the user has hit a button to
      // return to a previous step or to the beginning of the form. Therefore, don't carry out
      // any validation for the current step because that would be unnecessary and rude.
      return
    }

    // Fire preventDefault() outside of the validation check, or else the form will
    // go through no matter what if the validation check itself has any errors.
    event.preventDefault()

    if (!this.validateForm()) {
      this.firstInvalidField.focus()
    } else {
      this.element.submit()
    }
  }

  validateForm() {
    let isValid = true
    this.formFields.forEach((field) => {
      if (!this.validateField(field)) isValid = false
    })
    return isValid
  }

  validateField(field) {
    // For a <select> dropdown enhanced as an autocomplete, the blur event fires instead
    // on an input element serving up its options as a combobox, and sometimes on an <li>
    // behaving as an option, and checkValidity() is definitely not going to work on one
    // of those. Awkward. Look for the <select> dropdown that’s being enhanced, if one
    // exists, and if not then check the field supplied as the argument.

    let fieldToCheck

    if (document.getElementById(`${field.id}-select`)) {
      // In this case, it's either an enhanced autocomplete, or validation is firing on
      // one of an enhanced autocomplete's options, so validate the actual select element:
      fieldToCheck = document.getElementById(`${field.id}-select`)
    } else {
      fieldToCheck = field
    }

    // If it’s still not a proper field, get outta here
    if (!this.shouldValidateField(fieldToCheck)) return true

    let isValid = fieldToCheck.checkValidity()

    // The fieldContainer we’re assigning here should always have the [data-field-container]
    // attribute regardless of whether it’s a <fieldset> or a <div>.
    let fieldContainer = fieldToCheck.closest('[data-field-container]')
    if (fieldContainer) fieldContainer.setAttribute('data-field-valid', isValid)

    this.refreshErrorForInvalidField(fieldToCheck, isValid)
    return isValid
  }

  shouldValidateField(field) {
    return this.formFields.includes(field)
      && ['file', 'reset', 'submit', 'button'].indexOf(field.type) === -1
      && isVisible(field)
      && !field.disabled
  }

  isFieldDirty(field) {
    if (!this.shouldValidateField(field)) return false

    // A field’s container will get the attribute [data-field-valid] after the
    // first time it’s blurred, and we don’t want to harangue someone prior to
    // a field being blurred, so that attribute is all we need to check for.
    let fieldContainer = field.closest('[data-field-container]')
    return fieldContainer.hasAttribute('data-field-valid')
  }

  refreshErrorForInvalidField(field, isValid) {
    this.removeExistingErrorMessage(field)
    if (!isValid) this.showErrorForInvalidField(field)
  }

  removeExistingErrorMessage(field) {
    let fieldContainer = field.closest('[data-field-container]')
    if (!fieldContainer) return

    let existingErrorMessageElement = fieldContainer.querySelector('.form-error')
    if (existingErrorMessageElement)
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
  }

  showErrorForInvalidField(field) {
    let fieldContainer = field.closest('[data-field-container]')
    let errorContainer = fieldContainer.querySelector('[data-error-container]')

    errorContainer.insertAdjacentHTML('beforeend', this.buildFieldErrorHtml(field))
  }

  buildFieldErrorHtml(field) {
    // If we don’t have a custom error message for the field, use the browser’s built-in message:
    let errorMessage = field.getAttribute('data-error-message') || field.validationMessage

    return `<p class="form-error">
      <svg class="icon" style="fill:currentColor" width="18" height="18">
        <use href="#icon-info" xlink:href="#icon-info" />
      </svg>
      ${errorMessage}
    </p>`
  }

  get formFields() {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/elements
    return Array.prototype.slice.call(this.element.elements)
  }

  get firstInvalidField() {
    return this.formFields.find(field => !field.checkValidity())
  }


}
