import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['source', 'button']

  connect() {
    if (document.queryCommandSupported('copy')) {
      this.buttonTarget.removeAttribute('hidden')
    }
  }

  copy(e) {
    e.preventDefault()
    this.sourceTarget.select()
    document.execCommand('copy')
  }
}
