import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'select',
  ]

  initialize() {
    let currentCategory = this.selectTarget.value
    let currentHash = window.location.hash.replace('#', '')

    // Give preference to an existing value in the <select> element over
    // a hash, since that hash might not have anything to do with the
    // selection options.
    let initialSelection = currentCategory || currentHash

    // However, if we're coming in with a valid hash and no existing
    // value in the <select> element, then update its value:
    if (initialSelection !== currentCategory) this.selectTarget.value = initialSelection

    this.updatePanels(initialSelection)
  }

  connect() {
    this.selectTarget.addEventListener('change', this.handleCategory)
  }

  disconnect() {
    this.selectTarget.removeEventListener('change', this.handleCategory)
  }

  handleCategory = (e) => {
    this.updatePanels(this.selectTarget.value)
    this.manageHash(this.selectTarget.value)
  }

  manageHash = (categoryId) => {
    if (window.location.hash !== categoryId) {
      window.location.hash = categoryId
    }
    history.pushState(null, null, `#${categoryId}`)
  }

  mapUpdatePanels(e) {
    this.updatePanels(e.detail.categoryId)
    this.manageHash(e.detail.categoryId)
    this.selectTarget.value = e.detail.categoryId
  }

  updatePanels(categoryId) {
    let panels = [...this.element.querySelectorAll('[data-panel]')]

    panels.forEach(panel => {
      let panelId = panel.getAttribute('data-panel')
      let isCurrentPanel = panelId === categoryId

      isCurrentPanel
        ? panel.removeAttribute('hidden')
        : panel.setAttribute('hidden', 'true')
    })
  }

}
