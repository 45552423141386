import { Controller } from 'stimulus'

export default class extends Controller {

  modalTriggered = new CustomEvent('modaltriggered', {
    detail: this.element.getAttribute('data-trigger')
  })

  triggerModal(e) {
    if (e.type === 'keydown' && (e.keyCode !== 32 && e.keyCode !== 13)) {
      return
    }

    // Ensure the preventDefault call happens after the check for the space (32)
    // and enter (13) keys above, so that it doesn't interrupt other key presses.
    e.preventDefault()

    document.dispatchEvent(this.modalTriggered)
  }

}
