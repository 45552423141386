import { Controller } from 'stimulus'

export class CollapseBase extends Controller {

  isTargetOpen = (target) => {
    return target.getAttribute('aria-hidden') === 'true' ? false : true
  }

  handleCollapse(element, button, shouldOpen, delay) {
    // Wrangle all the styling and DOM-related toggling for list collapse/expansion
    element.style.maxHeight = shouldOpen ? `${element.scrollHeight}px` : null
    shouldOpen
      ? (
        element.setAttribute('aria-hidden', false),
        element.classList.add('is-visible')
      )
      // Delay hiding long enough to first allow the CSS transition to do its thing
      : (
        element.setAttribute('aria-hidden', true),
        setTimeout(() => {
          element.classList.remove('is-visible')
        }, delay)
      )
    button.setAttribute('aria-expanded', shouldOpen)
  }

}
