import scrollSnapPolyfill from 'css-scroll-snap-polyfill'
import Cookies from 'js-cookie'

function scrollEffect() {

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.7
  };

  function callback(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (entry.target.classList.contains('last-snap-item')) {
          if (document.querySelector('html').style.scrollSnapType == 'none') {
            document.querySelector('html').style.scrollSnapType = 'y mandatory';
          } else {
            document.querySelector('html').style.scrollSnapType = 'none';
          }
        }
        entry.target.classList.add('fade-in');
        entry.target.classList.remove('fade-out');
      } else {
        entry.target.classList.add('fade-out');
        entry.target.classList.remove('fade-in');
      }
    });
  }

  if (window.innerWidth > 500) {
    const observer = new IntersectionObserver(callback, options);

    const fadeEls = [...document.querySelectorAll('.fade')];
    fadeEls.forEach(el => observer.observe(el));
  }

  scrollSnapPolyfill();

  let twentyQuote = parseInt(Cookies.get('twenty-quote'));

  if (!twentyQuote || twentyQuote >= 3) {
    twentyQuote = 1;
  } else if (twentyQuote < 3) {
    twentyQuote += 1;
  }

  document.querySelector('.quote' + twentyQuote).style.display = 'block';

  Cookies.set('twenty-quote', twentyQuote);

  document.querySelector('header').style.scrollSnapAlign = 'start';
  
};

if (document.querySelector('.quote-container')) {
  scrollEffect();
}
