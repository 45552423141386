import { Controller } from 'stimulus'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'
import {
  getTransitionDuration,
  viewportSize
} from '../utils'
import A11yDialog from 'a11y-dialog'


// The nav menu is essentially a modal dialog, because when it’s open,
// it covers the available screen space and should therefore trap tabs,
// etc., as explained here:
// https://plousia.com/blog/how-create-accessible-mobile-menu
// So we’re treating it with the same considerations.
//
// Because this menu is for small viewports only, however, it should also:
// - Be available when viewportSize().isSm.matches is true
// - If shown, hide when viewportSize().isSm.matches is false


export default class extends Controller {
  static targets = [
    'navMenuWrapper',
    'navMenu',
    'navMenuToggle'
  ]

  connect() {
    if (viewportSize().isSm.matches) {
      this.setupNavMenu()
    }

    this.animationDuration = getTransitionDuration(this.element.querySelector('.nav-menu__container'))

    viewportSize().isSm.addListener(this.handleBreakpoint)
  }

  disconnect() {
    viewportSize().isSm.removeListener(this.handleBreakpoint)
    clearAllBodyScrollLocks()
  }

  setupNavMenu() {
    let navMenuOptions = {
      focusContainer: this.navMenuWrapperTarget
    }

    this.navMenu = new A11yDialog(this.navMenuTarget, navMenuOptions)
    this.navMenuTarget.removeAttribute('data-initial')

    // Ensure button content is handled regardless of how the menu dialog closes,
    // as may happen if someone hits the escape key instead of using the button:
    this.navMenu.on('hide', () => {
      this.handleButtonContent(false)
    })
  }

  toggle = (e) => {
    if (this.navMenu.shown) {
      this.close()
    } else {
      this.open()
    }

  }

  open = (e) => {
    // First, scroll to the top of the page to ensure the menu's toggle button
    // is fully within view before disabling body scrolling:
    window.scrollTo(0, 0)
    disableBodyScroll(this.navMenuTarget)

    // Show the menu dialog:
    this.navMenu.show()

    // And animate it:
    this.element.classList.add('is-open')

    // Update the menu toggle button accordingly:
    this.handleButtonContent(true)
  }

  close = (e) => {
    enableBodyScroll(this.navMenuTarget)

    // Animate the menu closing:
    this.element.classList.add('is-closing')
    this.element.classList.remove('is-open')

    // And give the animation time to wrap up before hiding the menu dialog:
    setTimeout(() => {
      this.element.classList.remove('is-closing')
      this.navMenu.hide()
    }, this.animationDuration)

    // Update the menu toggle button accordingly:
    this.handleButtonContent(false)
  }

  handleBreakpoint = (e) => {
    // Keep an array of the attributes that a11y-dialog adds to the dialog element,
    // so that we can easily clean them up when we need to destroy the dialog
    let a11yDialogAttrs = [
      'aria-hidden',
      'aria-modal',
      'role',
      'tabindex'
    ]

    if (e.matches) {
      this.setupNavMenu()
    } else {
      // Tear it all down, including the aforementioned a11y-dialog attributes
      this.navMenu.destroy()
      a11yDialogAttrs.map(attr => this.navMenuTarget.removeAttribute(attr))
    }
  }

  handleButtonContent(menuIsShown) {
    let button = this.navMenuToggleTarget
    let openString = `<span class="u-vh">${gettext('Open')} </span>Menu`
    let closeString = `${gettext('Close')}<span class="u-vh"> Menu</span>`

    button.innerHTML = menuIsShown ? closeString : openString
  }
}

