document.addEventListener("DOMContentLoaded", function() {

  // we want thee  autocomplete div  for country
  const countryValue = document.querySelector('.id_reporter_contact_info-reporter_contact_country-class > [data-controller="form--autocomplete"]');
  const provinceInputText = document.querySelector('.id_reporter_contact_info-reporter_contact_province-class');
  const provinceSelect = document.querySelector('.id_reporter_contact_info-reporter_contact_province_select-class');

  // these aren't on every page, so just check that they exist so
  // we don't get js errors.
  // Set this up to hide initially
  if (provinceSelect) {
    provinceSelect.style.display = 'none';
  }

  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'data-form--autocomplete-selection-value') {
        // Handle attribute change
        const newValue = countryValue.getAttribute('data-form--autocomplete-selection-value');
        // console.log(`Attribute 'data-form--autocomplete-selection-value' changed to: ${newValue}`);
        
        // Store the selected value in localStorage
        localStorage.setItem('selectedValue', newValue);

        // Canada has the value of 42
        if (newValue === '42') {
          provinceInputText.style.display = 'none';
          provinceSelect.style.display = 'inherit';
        } else {
          provinceInputText.style.display = 'inherit';
          provinceSelect.style.display = 'none';
        }
      }
    }
  });

  const config = { attributes: true };
  if (countryValue) {
    observer.observe(countryValue, config);

    // Retrieve the stored value on page load
    const storedValue = localStorage.getItem('selectedValue');
    if (storedValue) {
      // Update the UI based on the stored value
      countryValue.setAttribute('data-form--autocomplete-selection-value', storedValue);
      countryValue.dispatchEvent(new Event('change'));
    }
  }
});
