import { Controller } from 'stimulus'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'
import A11yDialog from 'a11y-dialog'


export default class extends Controller {
  static targets = [
    'content'
  ]

  connect() {
    this.modal = new A11yDialog(this.element)

    document.addEventListener('modaltriggered', this.handleOpen)

    this.modal.on('show', () => {
      disableBodyScroll(this.element)
    })
    this.modal.on('hide', () => {
      enableBodyScroll(this.element)
    })
  }

  disconnect() {
    document.removeEventListener('modaltriggered', this.handleOpen)
    clearAllBodyScrollLocks()
  }

  handleOpen = (e) => {
    if (e.detail === this.element.id) {
      this.open()
    }
  }

  open = (e) => {
    this.modal.show()
  }

  close = (e) => {
    this.modal.hide()
  }

}
