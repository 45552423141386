import { CollapseBase } from './collapse_base'

export default class extends CollapseBase {
  static targets = ['panel', 'toggleButton', 'heading']

  initialize() {
    let panel = this.panelTarget
    let currentHash = window.location.hash.replace('#', '')

    if (panel.classList.contains('starts-open') || (this.headingTarget.id && this.headingTarget.id  === currentHash)) {
      panel.setAttribute('aria-hidden', false)
      this.toggleButtonTarget.setAttribute('aria-expanded', true)
      panel.style.maxHeight = `${panel.scrollHeight}px`
      panel.classList.remove('starts-open')
      panel.classList.add('is-visible')
    } else {
      panel.setAttribute('aria-hidden', true)
      this.toggleButtonTarget.setAttribute('aria-expanded', false)
    }
  }

  connect() {
    this.collapseDuration = 0

    let collapseDurationProp =
      window
        .getComputedStyle(this.panelTarget)
        .getPropertyValue('transition-duration')
        .replace(/[^0-9]$/, '')

    this.collapseDuration = parseFloat(collapseDurationProp) * 1000
  }

  toggle(e) {
    let isOpen = this.isTargetOpen(this.panelTarget)

    this.handleCollapse(
      this.panelTarget,
      this.toggleButtonTarget,
      !isOpen,
      this.collapseDuration
    )

    window._paq.push([
      'trackEvent',
      `collapsible ${isOpen ? 'closed' : 'expanded'}`,
      `collapsible title: ${this.toggleButtonTarget.innerText}`
    ])
  }
}
