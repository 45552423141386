// David Walsh's debouncer
export function debounce(func, wait, immediate) {
  let timeout
  return function() {
    let context = this
    let args = arguments
    let later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
 
export function isVisible(element) {
  return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)
}

function ignoreOutsideEvent(event, element) {
  console.log('ignoreOutsideEvent:', element, event)
  // If the specified element doesn't contain the event's target, ignore the event.
  // This is one of the few ways to e.g. interrupt touch events to prevent scrolling 
  // behind a modal in iOS.
  if ( !element.contains(event.target) ) {
    event.preventDefault()
    event.stopPropagation()
  }
}

// Instead of the two following body scroll control functions, use:
// import {
  // disableBodyScroll,
  // enableBodyScroll,
  // clearAllBodyScrollLocks
// } from 'body-scroll-lock'

// export function disableBodyScroll(baseEl) {
  // // document.body.setAttribute('style', 'overflow:hidden')
  // // let windowHeight = window.innerHeight
  // // document.documentElement.style.height = `${windowHeight.toString()}px`
  // // document.body.style.height = `${windowHeight.toString()}px`
  // document.documentElement.setAttribute('data-modal-active', 'true')
  // document.addEventListener('touchstart', ignoreOutsideEvent.bind(null, baseEl), { passive: false })
  // document.addEventListener('touchmove', ignoreOutsideEvent.bind(null, baseEl), { passive: false })
  // // document.addEventListener('pointerdown', ignoreOutsideEvent.bind(null, baseEl), { passive: false })
  // // document.addEventListener('pointermove', ignoreOutsideEvent.bind(null, baseEl), { passive: false })
// }
// export function enableBodyScroll(baseEl) {
  // // document.documentElement.setAttribute('style', '')
  // // document.body.setAttribute('style', '')
  // document.documentElement.setAttribute('data-modal-active', 'false')
  // document.removeEventListener('touchstart', ignoreOutsideEvent, { passive: false })
  // document.removeEventListener('touchmove', ignoreOutsideEvent, { passive: false })
  // // document.removeEventListener('pointerdown', ignoreOutsideEvent, { passive: false })
  // // document.removeEventListener('pointermove', ignoreOutsideEvent, { passive: false })
// }


export function getFocusableEls(baseEl) {
  // If this function's limits turn out to be a problem, likely best to use
  // ally.is.focusable from ally.js: https://github.com/medialize/ally.js
  let focusables = baseEl.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), details, iframe, [tabindex], video[controls], audio[controls]')
  return Array.prototype.slice.call(focusables)
}

export function trapTabs(event, focusableEls) {
  let firstFocusableEl = focusableEls[0]
  let lastFocusableEl = focusableEls[focusableEls.length - 1]

  let handleBackwardTab = () => {
    if ( document.activeElement === firstFocusableEl ) {
      event.preventDefault()
      lastFocusableEl.focus()
    }
  }
  let handleForwardTab = () => {
    if ( document.activeElement === lastFocusableEl ) {
      event.preventDefault()
      firstFocusableEl.focus()
    }
  }
  if (event.keyCode === 9) {
    if ( focusableEls.length === 1 ) {
      event.preventDefault()
      return
    }
    event.shiftKey ? handleBackwardTab() : handleForwardTab()
  }
}

export function focusOnElement(el) {
  // Adapted from Anika Henke's solution:
  // https://github.com/selfthinker/dokuwiki_template_writr/blob/master/js/skip-link-focus-fix.js
  //
  // This still isn't working for VoiceOver in iOS.

  if (!el) return

  let focusables = getFocusableEls(document.body)
  let element = document.querySelector(el)

  if (!element) return

  if (focusables.indexOf(element) === -1) {
    // The element isn't focusable; we will temporarily make it so
    element.setAttribute('tabindex', -1)
    element.focus()

    // Don't keep the tabindex attribute any longer than necessary —
    // remove it as soon as focus leaves the element
    element.addEventListener('blur', function(e) {
      this.removeAttribute('tabindex')
    })
    element.addEventListener('focusout', function(e) {
      this.removeAttribute('tabindex')
    })
  }
}

// Deprecate and ditch opening external links in new windows.
// It's just not great to do:
// https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=325#change-on-request
// https://www.nngroup.com/articles/the-top-ten-web-design-mistakes-of-1999/
// https://web.archive.org/web/20021004212428/http://diveintoaccessibility.org/day_16_not_opening_new_windows.html
// https://adrianroselli.com/2020/02/link-targets-and-3-2-5.html
//
// export function externalLinks(element) {
  // if (element === undefined) element = document
// 
  // const currentHostname = window.location.hostname
  // // for (let links = element.getElementsByTagName('a'), i = 0; i < links.length; i++) {
  // for (let links = element.querySelectorAll('a'), i = 0; i < links.length; i++) {
    // const link = links[i];
    // if (link.classList.contains('js-no-ext-icon')) continue;
    // link.getAttribute('href') &&
      // link.hostname !== currentHostname &&
      // (link.getAttribute('href').match(/^http:\/\//) || link.getAttribute('href').match(/^https:\/\//)) &&
      // !link.getAttribute('href').match(/^mailto:/) &&
      // !link.getAttribute('href').match(/^tel:/) &&
      // // !link.classList.contains('js-no-ext-icon') &&
      // (link.target = "_blank")
  // }
// }

export function viewportSize() {
  // Should be the same as what's declared in the breakpoints map
  // (@bp[header-sm] and @bp[header-lg]) in css/global/variables.less
  const VIEWPORT_IS_SM = window.matchMedia('(max-width: 47.99em)')
  const VIEWPORT_IS_LG = window.matchMedia('(min-width: 48em)')

  return {
    isSm: VIEWPORT_IS_SM,
    isLg: VIEWPORT_IS_LG
  }
}

export function camelize(str) {
  return str.split(/[-_]/)
    .map(w => w.replace(/./, m => m.toUpperCase()))
    .join('').replace(/^\w/, c => c.toLowerCase())
}

export function getTransitionDuration(element) {
  let inSeconds = /\ds$/;
  let inMilliseconds = /\dms$/;
  let durationValue =
    window
      .getComputedStyle(element)
      .getPropertyValue('transition-duration')

  let ds = durationValue.replace(/[^0-9]$/, '')

  if (ds === '0') {
    console.warn('getTransitionDuration found a transition-duration value of 0 — check CSS declaration is set properly')
  }
  if (!durationValue.match(inMilliseconds) && !durationValue.match(inSeconds)) {
    console.warn('getTransitionDuration failed: make sure that CSS transition is declared in seconds (s) or milliseconds (ms)')
  }

  return durationValue.match(inMilliseconds) ? parseFloat(ds) : parseFloat(ds) * 1000
}
