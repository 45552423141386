import { Controller } from 'stimulus'
// import 'plyr/dist/plyr.css'
import 'plyr/src/sass/plyr.scss'
import Plyr from 'plyr/src/js/plyr.js'

export default class extends Controller {

  plyrCreatedEvent = new CustomEvent('plyrCreated')
  gettext = window.django.gettext

  initialize() {
    this.options = {
      loadSprite: false,
      disableContextMenu: false,
      speed: {
        // 7 settings seems a bit excessive for our purposes
        options: [0.5, 1, 1.5, 2],
      },
      quality: {
        default: 720
      },
      controls: [
        'play-large',
        // 'restart',
        // 'rewind',
        'play',
        // 'fast-forward',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        // 'pip',
        // 'airplay',
        'fullscreen',
      ],
      i18n: {
        restart: gettext('Restart'),
        rewind: gettext('Rewind {seektime}s'),
        play: gettext('Play'),
        pause: gettext('Pause'),
        fastForward: gettext('Forward {seektime}s'),
        seek: gettext('Seek'),
        seekLabel: gettext('{currentTime} of {duration}'),
        played: gettext('Played'),
        buffered: gettext('Buffered'),
        currentTime: gettext('Current time'),
        duration: gettext('Duration'),
        volume: gettext('Volume'),
        mute: gettext('Mute'),
        unmute: gettext('Unmute'),
        enableCaptions: gettext('Enable captions'),
        disableCaptions: gettext('Disable captions'),
        enterFullscreen: gettext('Enter fullscreen'),
        exitFullscreen: gettext('Exit fullscreen'),
        frameTitle: gettext('Player for {title}'),
        captions: gettext('Captions'),
        settings: gettext('Settings'),
        menuBack: gettext('Go back to previous menu'),
        speed: gettext('Speed'),
        normal: gettext('Normal'),
        quality: gettext('Quality'),
        loop: gettext('Loop'),
        start: gettext('Start'),
        end: gettext('End'),
        all: gettext('All'),
        reset: gettext('Reset'),
        disabled: gettext('Disabled'),
        enabled: gettext('Enabled'),
        qualityBadge: {
          2160: '4K',
            1440: 'HD',
            1080: 'HD',
            720: 'HD',
            576: 'SD',
            480: 'SD',
        },
      },
    }

    document.dispatchEvent(this.plyrCreatedEvent)
    this.player = new Plyr(this.element, this.options)

    // Fire off some piwik/matomo events
    this.player.on('playing', (e) => {
      window._paq.push([
        'trackEvent',
        'video-event',
        'started playing',
        this.player.media.currentSrc
      ])
    })
    this.player.on('ended', (e) => {
      window._paq.push([
        'trackEvent',
        'video-event',
        'ended/completed playing',
        this.player.media.currentSrc
      ])
    })
    this.player.on('error', (e) => {
      window._paq.push([
        'trackEvent',
        'video-event',
        'video error',
        e
      ])
    })

    // Bind the trackCaptionsToggle function to the correct scope
    let trackCaptionsToggle = this.trackCaptionsToggle.bind(this)

    this.player.on('ready', (e) => {
      // plyr fires off captions events every time a video is loaded, depending on what it remembers of a user’s preferences,
      // but that’s not as useful to us for tracking purposes as knowing when someone actually toggles the captions, so:
      const captionsBtn = e.detail.plyr.elements.buttons.captions
      captionsBtn.addEventListener('click', trackCaptionsToggle)
    })

  }

  captionsEnabled() {
    return this.player.captions.active
  }

  trackCaptionsToggle(e) {
    window._paq.push([
      'trackEvent',
      'video-event',
      `captions ${this.captionsEnabled() ? 'enabled' : 'disabled'}`,
      this.player.media.currentSrc
    ])
  }

}

