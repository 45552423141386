import {
  viewportSize,
  getTransitionDuration
} from '../utils'
import { CollapseBase } from './collapse_base'

export default class extends CollapseBase {
  static targets = ['navlist', 'toggleButton']

  initialize() {
    let isSm = viewportSize().isSm.matches
    this.navlistTarget.setAttribute('aria-hidden', isSm)

    // Listen for height changes coming from any sublist,
    // and update the nav list's height accordingly:
    this.element.addEventListener('sublistheightchange', e => {
      let list = this.navlistTarget
      if (this.isTargetOpen(list)) list.style.maxHeight = `${list.scrollHeight + e.detail.sublistHeight()}px`
    })

  }

  connect() {
    viewportSize().isSm.addListener(this.handleBreakpoint)

    this.collapseDuration = getTransitionDuration(this.navlistTarget)
  }

  disconnect() {
    viewportSize().isSm.removeListener(this.handleBreakpoint)
  }

  toggleList = e => {
    this.handleCollapse(
      this.navlistTarget,
      this.toggleButtonTarget,
      !this.isTargetOpen(this.navlistTarget),
      this.collapseDuration
    )
  }

  handleBreakpoint = e => {
    let isSm = viewportSize().isSm.matches

    this.handleCollapse(
      this.navlistTarget,
      this.toggleButtonTarget,
      !isSm,
      this.collapseDuration
    )
    this.navlistTarget.style.maxHeight = isSm ? '0' : 'none'
  }

}
