import { viewportSize } from '../utils'
import { CollapseBase } from './collapse_base'

export default class extends CollapseBase {
  static targets = ['sublist', 'toggleButton', 'toggleIconV']

  initialize() {
    // The sublist target element may not always be present;
    // use built-in has[Name]Target check to find out
    if (this.hasSublistTarget && this.sublistTarget.classList.contains('starts-open')) {
      let activeSublist = this.sublistTarget
      activeSublist.style.maxHeight = `${activeSublist.scrollHeight}px`
      activeSublist.classList.remove('starts-open')
      activeSublist.classList.add('is-visible')
    }
  }

  connect() {
    viewportSize().isSm.addListener(this.handleResize)
    this.collapseDuration = 0

    if (this.hasSublistTarget) {
      let collapseDurationProp =
        window
          .getComputedStyle(this.sublistTarget)
          .getPropertyValue('transition-duration')
          .replace(/[^0-9]$/, '')

      this.collapseDuration = parseFloat(collapseDurationProp) * 1000
    }
  }

  disconnect() {
    viewportSize().isSm.removeListener(this.handleResize)
  }

  // Set up custom event to fire off with data about height changes so that
  // section_nav_controller.js can listen and update its own height as necessary:
  sublistHeightChange = new CustomEvent('sublistheightchange', {
    bubbles: true,
    detail: { sublistHeight: () => this.sublistTarget.scrollHeight }
  })

  toggleSublist = e => {
    let isOpen = this.isTargetOpen(this.sublistTarget)

    this.handleCollapse(
      this.sublistTarget,
      this.toggleButtonTarget,
      !isOpen,
      this.collapseDuration
    )
    this.element.dispatchEvent(this.sublistHeightChange)
  }

  handleResize = e => {
    if (this.hasSublistTarget && this.isTargetOpen(this.sublistTarget)) {
      this.sublistTarget.style.maxHeight = `${this.sublistTarget.scrollHeight}px`
      this.element.dispatchEvent(this.sublistHeightChange)
    }
  }

}
