import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'keyInput',
    'image',
    'audio',
    'audioSrc',
  ]

  static values = {
    captchaKey: String,
    imageUrl: String,
    audioUrl: String,
    refreshUrl: String,
  }

  initialize() {
    // We're enhancing the captcha's audio with fancy buttons,
    // so hide the audio element itself:
    this.audioTarget.removeAttribute('controls')

    this.imageTarget.insertAdjacentHTML('afterend', this.refreshButtonTemplate())
    this.imageTarget.insertAdjacentHTML('afterend', this.audioButtonTemplate())
  }

  captchaKeyValueChanged() {
    this.reloadAudioSrc(this.audioUrlValue)
    this.imageTarget.src = this.imageUrlValue
    this.keyInputTarget.value = this.captchaKeyValue
  }

  audioButtonTemplate() {
    return `<button
      type="button"
      class="btn btn--dark u-mr-xs"
      data-action="click->form--captcha#playAudioCaptcha">
      <svg focusable="false"
        class="icon u-fill--inherit u-mr-xs"
        width="24"
        height="24">
        <use href="#icon-audio" xlink:href="#icon-audio" />
      </svg>
      ${gettext('Play audio')}
      <span class="u-vh">
        ${gettext('Audio captcha: please listen and type the letters you hear.')}
      </span>
    </button>`
  }

  refreshButtonTemplate() {
    return `<button
      type="button"
      class="btn btn--dark"
      data-action="click->form--captcha#refreshCaptcha">
      <svg focusable="false"
        class="icon u-fill--inherit u-mr-xs"
        width="24"
        height="24">
        <use href="#icon-refresh" xlink:href="#icon-refresh" />
      </svg>
      ${gettext('Refresh')}
    </button>`
  }

  playAudioCaptcha(event) {
    if (!this.audioTarget.paused) {
      this.audioTarget.pause()
    } else {
      // Always have the audio captcha start from the beginning:
      this.audioTarget.load()
      // Then play:
      this.audioTarget.play()
    }
  }

  reloadAudioSrc(newSrc) {
    this.audioTarget.src = newSrc
    this.audioTarget.load()
  }

  refreshCaptcha() {
    axios.get(this.refreshUrlValue, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then(
      (success) => {
        this.captchaKeyValue = success.data['key']
        this.imageUrlValue = success.data['image_url']
        this.audioUrlValue = success.data['audio_url']
      },
    )
  }


}
