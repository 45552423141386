// import { TweenMax, Power2, TimelineLite } from 'gsap/TweenMax'
import ScrollMagic from 'scrollmagic'
// import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js'

// import sectionFadeTimeline from './section-fade-timeline.js'


const sectionFadeHandler = {
  sectionFadeController: new ScrollMagic.Controller(),

  sectionFadeScene: new ScrollMagic.Scene({
    triggerElement: ".pin-container",
    triggerHook: "onLeave",
    duration: "800%"
  })
    // .setTween(sectionFadeTimeline)
    .setPin('.pin-container'),

  init() {
    this.sectionFadeController.addScene(this.sectionFadeScene)
    console.log('init sectionFadeController:', this)
    // console.log('sectionFadeScene timeline:', sectionFadeTimeline)
  },

  update() {
    this.sectionFadeController.update()
    // console.log('update sectionFadeController')
  },

  kill() {
    this.sectionFadeController.destroy()
    // console.log('kill sectionFadeController')
  }
}

export default sectionFadeHandler
