import { Controller } from 'stimulus'

export default class extends Controller {

  initialize() {
    this.conditionalFields = Array.from(this.element.querySelectorAll('[data-condition-value]'));
    this.initialCheckedInputs = Array.from(this.element.querySelectorAll('input:checked'))

    // This isn't a great way of initially hiding the fields, but it'll do for now I guess
    this.conditionalFields.forEach(field => field.hidden = true)

    // Check to see if any of the conditional fields are technically required;
    // they won't initially be rendered with the `required` attribute due to
    // the custom server-side validation we need for them, but we still want to
    // stick the `required` attribute in the markup at this point to give us a
    // client-side validation hook.
    this.formFields.forEach(field => {
      if (
          field.closest('[data-condition-required]')
          && field.closest('[data-condition-required]').getAttribute('data-condition-required') === 'true'
          && !field.getAttribute('required')
      ) {
        field.setAttribute('required', 'required')
      }
    })

    // Check to see if any of the conditional fields should already be showing,
    // i.e. if a response they depend on is already checked on page load:
    this.initialCheckedInputs.forEach(input => {
      let initValue = input.value
      let initName = this.removeStepPrefix(input.name)

      this.handleConditionalFields(initName, initValue)
    })
  }

  connect() {
    this.element.addEventListener('change', this.onChange)
  }

  disconnect() {
    this.element.removeEventListener('change', this.onChange)
  }

  removeStepPrefix(string) {
    // Are ye a wizard, Harry? 
    // Check for step management input element.
    // If it's not there, then don't mangle the string:
    if (!this.element.querySelector('[id$="current_step"]')) return string

    // formtools adds a digital prefix by default,
    // but let's assume we might explicitly name steps at some point.
    let re = /^\w+-/
    return string.replace(re, '')
  }

  getDependentFields(fields, name) {
    return fields.filter(field => {
      return field.getAttribute('data-condition-name') === name
    })
  }

  handleConditionalFields(name, value) {
    let childFields = this.getDependentFields(this.conditionalFields, name)

    childFields.forEach(childField => {
      this.handleConditionalCascade(childField, value)
    })
  }

  handleConditionalCascade(field, conditionValue) {
    // Here, the `field` argument refers to a conditional field that also might
    // set conditions for another field, depending on its value.

    // First, handle the field's own hiddenness:
    field.hidden = conditionValue !== field.getAttribute('data-condition-value')

    // If `field` is not a set of radio buttons or a checkbox, then we can
    // reliably assume it has no dependent fields and get outta here.
    if (!field.querySelector('input[type="radio"]') && !field.querySelector('input[type="radio"]')) return false

    let fieldName = this.removeStepPrefix(field.querySelector('input').getAttribute('name'))
    let dependentFields = this.getDependentFields(this.conditionalFields, fieldName)

    if (field.hidden) {
      // Fields that depend on this field also need to hide
      dependentFields.forEach(field => field.hidden = true)
    } else {
      // Only reveal dependent fields if they already meet conditions for showing
      let checkedInputs = Array.from(field.querySelectorAll('input:checked'))

      dependentFields.forEach(field => {
        let matches = checkedInputs.filter(input => input.value === field.getAttribute('data-condition-value'))

        if (matches.length !== 0) {
          field.hidden = false
        }
      })
    }

  }

  onChange = (event) => {
    let value = event.target.value
    let name = this.removeStepPrefix(event.target.name)

    this.handleConditionalFields(name, value)
  }

  get formFields() {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/elements
    return Array.prototype.slice.call(this.element.elements)
  }


}
